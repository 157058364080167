import api from "./HttpService";

export default class SellerAccountService {
  updateSellerAccount(data) {
    return api.put(`/v1/seller_accounts/1`, { seller_accounts: data });
  }

  getSellerAccount() {
    return api.get(`/v1/seller_accounts/1`);
  }

  getBalance(seller_id) {
    return api.get(`/v1/seller_account_events/${seller_id}/balance`);
  }

  getTwentySellerAccountEvents() {
    return api.get(`/v1/seller_account_events`);
  }

  getCategories() {
    return api.get(`/v1/seller_accounts/categories`);
  }

  getEvents(seller_id, currentPage, date_range = {}) {
    return api.get(
      `/v1/sellers/${seller_id}/seller_account_events?start_date=${
        date_range.start_date
      }&end_date=${date_range.end_date}&offset=${(currentPage - 1) * 15}`
    );
  }

  getInvoices(seller_id) {
    return api.get(`/v1/seller_account_invoices/${seller_id}/invoices`);
  }

  getCategoryEvents(seller_id, category) {
    const params = {
      start_date: "2021-01-02",
      end_date: "2021-12-28",
    };
    return api.get(
      `/v1/sellers/${seller_id}/seller_account/category_events/${category}`,
      { params }
    );
  }

  createEvent(seller_id, data) {
    return api.post(`/v1/sellers/${seller_id}/seller_account_events`, data);
  }

  getSettings(seller_id) {
    return api.get(`/v1/sellers/${seller_id}/seller_account_setting`);
  }

  createSetting(seller_id) {
    return api.post(`/v1/sellers/${seller_id}/seller_account_setting`);
  }

  updateSetting(data) {
    return api.put(
      `/v1/sellers/${data.seller_id}/seller_account_setting`,
      data
    );
  }

  resetSetting(seller_id) {
    return api.delete(`/v1/sellers/${seller_id}/seller_account_setting`);
  }
}
