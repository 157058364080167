export default {
  //components
  components: {
    nav: {
      HomeDashboard: "主页",
      Dashboard: "儀表板",
      Quotes: "报价",
      New: "新报价",
      Orders: "订单",
      OrdersManagement: "订单总览",
      Import: "文件导入",
      Create: "新订单",
      CreateBeta: "Create (beta)",
      VolumeReverse: "折返小包",
      PickupDropoff: "拾件 / 送货",
      DutiesAndTaxes: "税费",
      Rejected: "导入失败订单",
      Overpacks: "外包",
      OverpacksManagement: "外包总览",
      Notifications: "通知",
      RulesConfiguration: "规则设置",
      TrackingPage: "物流追踪",
      NotificationSettings: "通知设置",
      Settings: "设置",
      ShippingRules: "运输规则",
      QuickStart: "快速入门",
      Advanced: "高级",
      BoxesProducts: "盒子和产品",
      Boxes: "包装盒",
      Printing: "标签打印",
      SalesChannels: "销售渠道",
      Products: "产品",
      Users: "用户",
      Customers: "顾客",
      Documents: "文件",
      Insurance: "保险",
      includeInsurance: "include insurance",
      Integrations: "集成",
      NewIntegration: "创建新集成",
      CouriersList: "物流商",
      ChannelsList: "频道",
      PriceTablesDeliveryTime: "Price Tables & Delivery Time",
      Onboard: "机上",
      Downloads: "下载",
      support: "支持",
      myExperience: "完成我的经历",
      Archives: "檔案",
      Accessibility: "無障礙",
      Account: "帐户",
      UserProfile: "我的简历",
      Company: "我的公司",
      NotificationsList: "通知",
      Contracts: "康特拉托斯",
      TrackingRuleNotifications: "追蹤通知規則",
      FiscalPayments: "財政支付",
    },
    searchData: {
      settingsTracking: "Tracking Settings",
      home: "Home",
      userProfile: "用户资料",
      userProfileEdit: "用户资料编辑",
      company: "公司",
      resetPassword: "重设密码",
      trackOrders: "追踪订单",
      newQuote: "新报价",
      ordersList: "订单",
      orderImport: "订单导入",
      orderCreateNew: "订单 创建新订单",
      volumeReverseList: "卷反向列表",
      orderPickupDropoff: "订单提货送达",
      overpacksList: "外包装清单",
      notificationsConfig: "通知配置",
      notificationsCreate: "创建通知",
      notificationsTracking: "通知跟踪",
      notificationsSettings: "通知设置",
      notificationsList: "通知列表",
      settingsQuickstart: "设置快速开始",
      settingsShippingRules: "设置运输规则",
      boxesProductsList: "箱子和产品列表",
      boxesList: "箱子列表",
      settingsPrinting: "设置打印",
      settingsSalesChannels: "设置销售渠道",
      settingsProducts: "设置产品",
      usersList: "用户列表",
      usersCreateNew: "创建新用户",
      settingsCustomers: "设置客户",
      settingsDocuments: "设置文档",
      settingsInsurance: "设置保险",
      userBalance: "用户余额",
      settingsAccessibility: "设置无障碍",
      contracts: "合同",
      dashboard: "仪表板",
      fiscalPayments: "財政支付",
      trackingRuleNotifications: "追蹤通知規則",
    },
    baseTemplate: {
      logout: "退出登录",
      topBar: {
        sideNav: "侧边浮动条",
        sideNavPopover: "置顶浮动条",
        topNav: "顶部浮动条",
        topNavPopover: "置左浮动条",
        lastEdit: "最后更改",
        saved: "打捞的",
        saving: "保存更改",
      },
      profile: {
        myProfile: "用户信息",
        myBalance: "我的余额",
        myCompany: "公司信息",
        fontSize: "文字大小: ",
        myContracts: "我的合同",
        conditions: "条件",
        myNotifications: "我的通知",
      },
      termsOfService: "服務條款",
    },
    chart: {
      bar: "条形图",
      line: "曲线图",
      area: "区域图",
    },
    donutChart: {
      pie: "扇形图",
      donut: "环形图",
      polarArea: "单边图",
    },
    mapChart: {
      hoverOverState: "悬停",
    },
    onboardRow: {
      moreAbout: "更多关于{val}信息",
    },
    stepper: {
      step: "步",
      agreeBeforeContinue: "请先同意费用汇总声明再继续.",
    },
    boxesList: {
      newBox: "创建新包装盒",
      newItem: "新项目",
      specificationsOf: "规格",
      containsHarmfulProducs: "此盒子含有有害产品",
      harmfilExample: "有害产品示例：锂电池,...",
      ifDoubt: "如果您有疑问，请",
      seeHarmfulList: "查看完整的有害材料清单",
      moreAbourShippingRules: "单击此处了解有关运输规则的更多信息",
      notContainsHarmfulProduct: "不，这个盒子不含有害产品.",
      containsHarmfulProduct: "是的，这个盒子含有有害产品",
      productData: "产品数据",
      saveProduct: "保存产品数据以供将来订单使用",
      metric: "厘米/千克 (cm & kg)",
      imperial: "英尺/英镑 (in & lb)",
      howAboutCreatingRule:
        "您知道您可以自动化某些设置吗？ 创建一个规则怎么样？",
    },
    modalEditSellerMetric: {
      title: "修改长度重量单位",
    },
    modalCreateCustomBox: {
      title: "创建新包装盒",
    },
    tableFilters: {
      title: "筛选条件",
      subTitle: "选择以下值以创建过滤器",
      addNewFilter: "添加新过滤器",
      clearFilters: "清除筛选条件",
      filterBy: "过滤: ",
      filteredBy: "筛选条件:",
      openFilterOptions: "清除筛选条件",
      clickToClearFilter: "筛选条件",
      clickToFilter: "点击筛选",
      trueText: "是",
      falseText: "否",
    },
    selectTableColumns: {
      title: "选择表格竖列",
      button: "表格竖列",
      unSelectAll: "取消选择所有表格竖列",
      selectAll: "选择全部表格竖列",
      shownColumns: "显示列",
      hiddenColumns: "隐藏列",
    },
    maxRows: {
      button: "横行",
    },
    pagination: {
      prevText: "以前的",
      nextText: "下一个",
    },
    boxesOverview: {
      mustHaveOne: "包装盒不能为空",
      packageDimenstions: "包装尺寸",
    },
    destructiveModal: {
      deleteBox: {
        title: "删除包装盒",
        cancel: "取消",
        ok: "确认",
        information: "确定删除包装盒",
      },
      addCustomBox: {
        title: "添加新包装盒",
        cancel: "取消",
        ok: "确定",
      },
      editMetric: {
        title: "修改厘米/千克模式",
        cancel: "取消",
        ok: "确认",
      },
    },
    unsavedWorkModal: {
      discard: "弃用",
      draft: {
        title: "恢复草稿？",
        resume: "恢复",
        information: "您有一个新草稿",
      },
      otherDraft: {
        title: "努力恢复中",
        information: "加载中... ",
      },
    },
    nbTable: {
      wereFound: "已找到",
      all: "全部",
      onThisPage: "在此页面",
      isSelected: "已选中",
      worksOnlyCurrentPage:
        "该过滤器仅适用于{onThisPageItems} 表中此页显示的项目，并非全部{totalItems} 项目。 为此，请使用过滤器按钮",
      sortBtn: "对表格这一页上的项目进行排序。（不对找到的所有项目进行排序）",
      properties: "特性",
      tableOptions: "表格选项",
      changeVisibilityReorderColumns: "更改可见性并重新排序列",
    },
    footer: {
      poweredBy: "供电 ",
      needHelp: "需要帮忙",
      configureYourAcc: "了解如何设置您的帐户",
      moreAboutCompany: "了解更多关于公司数据的信息",
      moreAboutSignature: "了解更多关于品牌和签名的信息",
      checkDocumentsAndTags: "查看我们的文件和标签指南",
      checkOurSupport: "查看我们的指南或给我们发送消息",
      learMoreAboutInsurance: "了解更多保險知識",
      learnMoreAboutWeightAndMeasurements: "了解有關重量和尺寸的更多信息",
      learnMoreAboutAccessibilityOptions: "了解有關輔助功能選項的更多信息",
    },
  },

  locales: {
    ptBR: "葡萄牙語（巴西）",
    en: "英語",
    cn: "英語",
  },

  //words
  warning: "警告",
  selectAnOption: "選擇一個選項",
  remove: "消除",
  next: "下一步",
  back: "返回",
  generalData: "常规数据",
  suggestedAction: "建议操作",
  suggestedActions: "建议操作",
  products: "产品",
  freight: "货运",
  view: "看法",
  cubedWeight: "体积重量",
  actions: "操作",
  confirmation: "确认",
  confirm: "确定",
  cancel: "取消",
  yes: "是",
  no: "否",
  save: "保存",
  close: "关闭",
  upload: "上传",
  uploading: "上传中。。。",
  update: "更新",
  updating: "更新中。。。",
  lastUpdate: "上一次更新",
  loading: "加载中",
  ignore: "忽略",
  invalid: "无效",
  enable: "启用",
  disable: "停用",
  edit: "编辑",
  create: "创建",
  creating: "创建中",
  delete: "删除",
  deleted: "已删除",
  clone: "克隆",
  resend: "重发",
  site: "网站",
  postalCode: "邮编",
  country: "国家",
  city: "城市",
  state: "州",
  insurance: "保险",
  generalDescription: "一般说明",
  includeInsurance: "包括保险",
  hasInsurance: "有保险",
  currency: "货币",
  collectedTaxesCurrency: "税收货币",
  orderCurrency: "订单货币",
  senderWord: "卖家",
  sender: "卖家 (DDP)",
  receiver: "买家 (DDU)",
  justReceiver: "接收者",
  taxDuties: "税务",
  box: "包装盒",
  item: "物件",
  items: "物件",
  itemOrItems: "物件 | 物件",
  width: "宽度",
  added: "添加",
  created: "创建",
  height: "高度",
  length: "长度",
  weight: "重量",
  abbreviatedWidth: "W",
  abbreviatedHeight: "H",
  abbreviatedLength: "L",
  abbreviatedWeight: "W",
  emptyWeight: "空重",
  totalWeight: "总重",
  sku: "Sku",
  name: "名",
  fullName: "全名",
  email: "電子郵件",
  placeholderEmail: "example@email.com",
  connect: "连接",
  disConnect: "断开",
  connected: "连接的",
  disConnected: "已断开连接",
  phone: "电话",
  whatsapp: "Whatsapp",
  sms: "信息",
  profile: "用户信息",
  password: "密码",
  confirmPassword: "确认密码",
  confirmOldPassword: "确认旧密码",
  confirmEmail: "确认电子邮件",
  description: "描述",
  originCountry: "原产国",
  category: "品类",
  hsCode: "海关编码",
  unitPrice: "单价",
  unitWeight: "单位重量",
  taxValue: "税费",
  quantity: "数量",
  total: "总计",
  totalValue: "总价",
  declaredValue: "申报价值",
  salesTaxValue: "營業稅值",
  dutiesTaxValue: "關稅稅值",
  freightValue: "运费值",
  freightCost: "运费",
  productsValue: "产品价值",
  dutiesTax: "关税",
  salesTax: "销售税",
  totalCost: "总消耗",
  deliveryTime: "交货时间",
  insuranceCost: "保险费",
  taxCost: "税费",
  ddpCost: "DDP 费用",
  details: "细节",
  page: "页面",
  pages: "页面",
  new: "新",
  dispatch: "提交",
  dispatched: "已派发",
  notDispatched: "未发货",
  label: "标签",
  correios: "邮政",
  others: "其他",
  contract: "合同",
  handlingTime: "准备时间",
  dimensions: "尺寸",
  date: "日期",
  add: "添加",
  rule: "规则",
  optional: "选修的",
  group: "團體",
  download: "下載",
  addedIn: "添加於",
  invalidFileFormat: "文件格式無效",
  dragDropFile: "拖放或點擊此處",
  this: "這",
  these: "這些",
  customer: "顧客",
  local: "當地的",

  order: "订单",
  orders: "订单",
  orderNumber: "订单号",
  orderNumberSales: "订单号销售",
  orderCreate: "创建新订单",
  orderImport: "订单导入",
  overpack: "外包",
  overpacks: "外包",
  product: "产品",
  quotes: "报价",
  taxes: "税",
  taxPayer: "税费支付者",
  customerName: "客户名",
  customerCountry: "客户国家",
  salesChannel: "销售渠道",
  salesChannels: "销售渠道",
  salesChannelOrderNumber: "销售渠道订单号",
  salesChannelOrderNumberShort: "销售渠道订单号 Nº",
  trackingNumber: "物流号",
  lastMileTrackingNumber: "后程物流号",
  firstMileTrackingNumber: "第一英里追踪号码",
  status: "状态",
  shipmentConfirmation: "航班确认",
  overpacked: "已合成外包",
  betweenDates: "Between Dates",
  startDate: "Start Date",
  endDate: "End Date",
  registrationDate: "報名日期",
  startRegistrationDate: "开始登记时间",
  endRegistrationDate: "介绍登记时间",
  creationDate: "创建日期",
  myCompanyName: "我的公司",
  company: "公司",
  document: "证件",
  documentType: "证件类型",
  documentNumber: "证件号",
  cpf: "CPF",
  cnpj: "CNPJ",
  cpfOrCnpj: "CPF/CNPJ",
  taxNumber: "稅號",
  number: "号码",
  address: "地址",
  addressNumber: "街道号",
  complement: "补充",
  comments: "备注",
  contactUs: "联系我们",
  complete: "完全的",
  incomplete: "不完整",
  erro: "Erro",
  sellerName: "卖家姓名",
  sellerEmail: "卖家邮箱",
  sellerPhone: "卖家电话",
  sellerCountry: "卖家国家",
  sellerZipCode: "卖家邮编",
  sellerCity: "卖家城市",
  sellerState: "卖家州/省",
  sellerAddress: "卖家地址",
  sellerAddressNumber: "卖家街道号",
  sellerAddressComplement: "卖家地址备注",
  sellerTaxNumber: "卖家税号",
  TaxNumberCpf: "稅號是 CPF",
  recommendedCnpj: "是有形体的人",
  sellerWebsite: "卖家网站",
  sellerLogoUrl: "賣家標誌網址 (JPG or PNG)",
  isCommercialDestinationMandatory: "是商业目的地吗？",
  sellerRfbCode: "卖家 RFB 代码",
  sellerLegalName: "卖家法定名称",
  collectedIcms: "收集的ICMS",
  collectedIi: "收集二",

  macro: "宏",
  macroStatus: "Macro Status",
  showMacro: "显示 Macro",
  micro: "微",
  microStatus: "Micro Status",
  showMicro: "显示 Micro",
  active: "活跃",
  inactive: "不活躍的",
  blocked: "已封锁",
  allowed: "已授权",

  nobordist: "Nobordist",
  carrier: "物流",
  seller: "卖家",

  selectOption: "选择一项",
  selectAll: "全选",
  selectAllOf: "全选",
  clear: "取消全选",
  clearSelected: "取消选择",
  selected: "已选择",
  typography: "版式",
  ad: "ad",

  quickActions: "快速行动",
  necessaryAction: "必要的行动",
  all: "全部",
  allItems: "所有项目",
  hello: "你好",
  value: "價值",
  totalItems: "总项目",
  destination: "目的地",
  table: "桌子",

  checkOurTutorial: "查看我们的教程",
  confirmDeletes: "确认删除 {val}?",
  confirmDelete: "确认删除 {val}?",
  balance: "餘額",
  errorMessages: {
    isNotPDF: "文件格式非.pdf",
    tryAgain: "请核对信息后再次尝试",
    required: "不能为空",
    invalidCPF: "无效 CPF",
    invalidCNPJ: "无效 CNPJ",
    invalidDocument: "无效证件",
    invalidZIPCode: "无效邮编",
    invalidPhone: "无效电话",
    invalidEmail: "无效邮件",
    lowerMoney: "应大于0.01",
    genericError: "",
  },

  warningMessages: {
    notActiveAccount: "您的帐户未激活。请联系管理员。",
  },

  orderStatus: {
    selectOption: "选择一项",
    new: "新订单",
    readyToShip: "准备就绪",
    pickUp: "拾件",
    shipped: "已装运",
    inTransit: "运输中",
    failedAttempt: "尝试失败",
    outForDelivery: "外出派送中",
    delivered: "已收件",
    deliveryFailed: "派送失败",
    partialDelivery: "部分派送",
    partialDeliveryFailed: "部分派送失败",
  },

  hsCodes: {
    acessoryNoBattery: "无电池配件",
    acessory: "含电池配件",
    audioVideo: "音像类",
    bagsLuggages: "箱包",
    boardGames: "棋盘游戏",
    booksCollectibles: "书籍&藏书",
    cameras: "相机",
    computersLaptops: "电脑&笔记本电脑",
    documents: "文件",
    dryFoodsSupplements: "干性食物",
    fashion: "时尚品",
    gaming: "游戏类",
    healthBeauty: "保健美容类",
    homeAppliances: "家用电器",
    homeDecor: "家居装饰",
    jewelry: "珠宝",
    mobilePhone: "手机",
    petAccessory: "宠物用品",
    shoes: "鞋类",
    sportLeisures: "运动休闲",
    tablets: "平板电脑",
    toys: "玩具",
    watches: "手表",
    others: "其他",
  },

  //countries
  countries: {
    unitedStates: "美国",
    china: "中国",
    france: "法国",
    unitedKingdom: "英国",
  },

  kilograms: "公斤",
  meters: "米",
  inches: "英吋",
  libras: "磅",

  //pages
  quotePage: {
    title: "获得报价",
    subtitle: "输入数据并点击末尾的“查看报价”以检查截止日期和价值",
    shipmentInformation: "航班信息",
    shipFrom: "航班来自（来源国）",
    pleaseEnterData: "请输入数据",
    shipDestination: "航班驶向(目的国)",
    orderInformation: "订单信息",
    boxInformation: "包装盒信息",
    createOrder: "创建订单",
    goToOrderCreate: "您想创建订单吗？单击此处从上次中断的地方继续",
  },

  shippingRulesPage: {
    title: "運輸規則",
    subtitle:
      "在此頁面上，您可以為特定欄位定義預設值，因此每當您造訪某個頁面時，它都已經預先填入了定義的欄位。",
    newRule: "新規則",
    editRule: "編輯規則",
    guide: {
      title: "首先，請在左側欄中至少新增一個條件",
      subtitle: "如果您願意，請點擊下面的按鈕查看已配置的規則。",
      goToShippingRules: "前往運輸規則",
    },
    shippingRuleTab: {
      description:
        "檢查並更改您先前配置的欄位。 具有較高優先權的規則可以覆寫具有較低優先權的規則。",
      createShippingRule: "建立運輸規則",
    },
    newRuleTab: {
      firstStepTitle: "定義您的規則條件",
      firstStepDescription: "選擇一個條件來定義何時呼叫您的動作",
      lastStepTitle: "定義將發生的操作",
      lastStepDescription: "在此欄位中，您將根據您的情況選擇應該發生的情況。",
      newAction: "新行動",
      defineAnAction: "定義一個動作",
      ruleName: "規則名稱",
      newCondition: "新條件",
    },
    fields: {
      priority: "優先事項",
      name: "姓名",
      condition: "狀態",
      action: "行動",
      expiration: "到期日",
      is_active: "積極的",
      quickActions: "快速行動",
    },
    tips: {
      separatedByCommas: "被逗號隔開",
      itemSku: "您可以傳遞郵遞區號列表，以逗號分隔",
      customerPostalCode: "輸入郵遞區號，以逗號分隔",
      defineFreightValueToBe: "輸入的值將以訂單貨幣表示：美元或巴西雷亞爾",
      priority: "如果有 2 條規則相互矛盾，則優先順序將決定應用哪一條",
      expiration: "該規則應適用到什麼時候",
      is_active: "如果您想建立規則但暫時不套用它，只需取消選擇此欄位即可",
    },
    operator: "操作員",
    value: "價值",
    conditions: {
      allShipments: "所有出貨",
      containsCategory: "包含類別",
      destinationCountry: "目的地國家",
      destinationState: "目的地州",
      itemSku: "商品庫存單位",
      numberOfItems: "東西的個數",
      receiverPostalCode: "收件人郵遞區號",
      sellerPrice: "賣家價格",
      shipmentWeight: "出貨重量",
      channel: "頻道",
    },
    actions: {
      alwaysSortByCourier: "始終按快遞排序",
      sortByCourierIf: "如果沒有給出數據，按快遞排序",
      alwaysUseIncoterm: "始終使用此 Incoterm",
      useIncotermIf: "如果未提供數據，請使用此 Incoterm",
      alwaysUseThisCourier: "始終使用此快遞",
      useThisCourierIf: "如果沒有提供數據，請使用此 Courier",
      neverUseCourier: "從不使用快遞",
      alwaysUseThisPackage: "始終使用此包",
      useThisPackageIf: "如果沒有給出數據，請使用此包",
      neverUsePackage: "從不使用包",
      alwaysShipFrom: "總是從",
      shipFromIf: "如果沒有給出數據，發貨自",
      defineFreightValueToBe: "预设运费成本",
      freightValueFromCost: "运费价格采取运费成本",
    },
    conditionOperators: {
      greaterThan: "比...更棒",
      greaterThanOrEqualTo: "大於或等於",
      lessThan: "少於",
      lessThanOrEqualTo: "小於或等於",
      EqualsTo: "等於",
      isAnyOf: "是以下任一個",
      isNoneOf: "沒有一個是",
      startsWith: "以。。開始",
      shipmentContains: "快件包含",
      shipmentDoesNotContain: "快件不含",
      shipmentOnlyContains: "快件僅包含",
    },
    rulePriorityOptions: {
      veryHigh: "很高",
      high: "高的",
      medium: "中等的",
      low: "低的",
      veryLow: "非常低",
    },
  },
  pickupPage: {
    title: "我的取货/交货",
    nextPickup: "下一次取货/交货",
    pickupDate: "计划取货日期",
    createPickup: "创建取货",
    deletePickup: "删除取货",
    informDate: "通知日期和时间以创建取货",
    sureDeletePickup: "您确定要删除这些取货吗",
    informType: "通知类型，并为取货选择日期",
    pu: "取货",
    do: "交货",
    nothing: "无",
    fields: {
      orderId: "订单ID",
      orderNo: "订单号",
      type: "类型",
      pickupID: "取货ID",
      pickupNumber: "取货号",
      pickupData: "取货日期",
    },
  },
  ordersListPage: {
    title: "订单总览",
    newOrder: "新指令",
    createOrder: "建立訂單",
    quotation: "科塔桑",
    ordersDeleted: "訂單已刪除",
    found: "无订单 | 订单 | 订单",
    orderNo: "订单号",
    salesChOrderNo: "销售渠道订单号",
    value: "金额",
    incoterm: "国际贸易术语",
    freightCost: "运费",
    postalCode: "邮编",
    salesChannel: "销售渠道",
    customerName: "客户名",
    country: "国家",
    city: "城市",
    contractName: "合同名",
    creationDate: "创建日期",
    estimatedDeliveryDate: "预估交付时间",
    label: "标签",
    firstMileLabel: "第一英里标签",
    invoice: "发票",
    shipperConfirmationPDF: "Ceva电池确认文件",
    dangerousGoodPDF: "危险品标签",
    status: "状态",
    trackingNumber: "物流号",
    insuranceCoverage: "保额",
    overpacked: "已合成外包",
    lastMileTrackingNumber: "后程物流号",
    lastMileCarrierNumber: "后程运单号",
    firstMileCarrier: "首程物流合同",
    firstMileTrackingNumber: "首程物流号",
    mini: "小型的",
    packet: "包",
    dispatchOrders: "派送订单",
    dispatchOrderError: "派送订单错误",
    downloadOrders: "下载订单信息",
    downloadVolumes: "下载小包信息",
    withoutOverpack: "无外包",
    trackNumberNotFound: "无法查询到该物流号",
    lastMileCarrierNotFound: "未找到后程运单号",
    andMore: "还有{val}",
    generateOverpack: "合成外包",
    actions: "操作",
    downloadOrderCosts: "下载订单成本",
    deleteOrders: "删除订单",
    generateLabel: "合成标签",
    generateInvoice: "合成发票",
    generateDocument: "Generate Document",
    generatePackingSlip: "合成装箱单",
    generateOnePackingSlip: "Generate packing slip",
    generateDangerousMultiple: "合成危险品标签",
    ifDangerousPersonal: "危险品标签联系方式",
    generateCN35: "合成CN35",
    printingAll: "打印全部标签",
    toTrackingPage: "物流页面",
    confirmDeleteOrder: "确认删除这些订单？",
    collectedIcms: "收集的ICMS",
    collectedIi: "收集的II",
    isCommercialDestination: "是商业目的地",
    sellerRfbCode: "卖家 RFB 代码",
    sellerLegalName: "卖家法定名称",
    downloadModal: {
      title: "文件已合成，请于下载页面下载",
      startDownload: "开始下载",
    },
    sellersName: "卖家姓名",
    sellerTaxNumber: "卖家税号",
    sellerInformations: "卖家信息",
    cn35GenerateFailed: "產生 cn35 為 {orderId} 失敗。",
    goToDownloadPage: "下載檔案已創建，您可以前往下載頁面查看",
  },
  ordersImportPage: {
    title: "進口訂單",
    selectFile: "找到您的 .csv 檔案並將其拖曳到下面的區域。",
    downloadHere: "在這裡下載",
    instructionsXLSFile: "說明 XLS 文件",
    sendFile: "恩維亞爾·阿基沃",
    downloadExampleFile: "下載電子表格模板",
  },
  importResultPage: {
    title: "导入摘要",
    summary: "概括",
    cancelImport: "取消导入",
    totalProduct: "全部产品",
    totalOrders: "全部订单",
    totalValid: "有效总计",
    totalRejected: "无效总计",
    noValidProducts: "未找到有效产品，请核实信息后重试",
    gotoProducts: "前往产品总览页面",
    importLog: "导入日志",
    productNumber: "产品号",
    productName: "产品名",
    orderNumber: "信息号",
    status: "Stat状态us",
    log: "日志",
    success: "成功",
    error: "错误",
    confirmCancel: "确认取消导入订单？",
    return: "返回",
  },
  orderCreatePage: {
    documentsAreMissing: "确实重要文件",
    includeDocuments: "请添加全部文件以创建订单",
    orderCreated: "订单已创建",
    orderUpdated: "订单已更新.",
    rejectOrderUpdated: "无效订单信息更改成功",
    dontForgetOverpack: "请记得合成外包 | 不要忘记发送此订单!",
    receiver: "收货人",
    sender: "Sender",
    shipment: "航班",
    courier: "物流商",
    overview: "概述",
    boxes: "外包装盒",
    finish: "结束",
    receiverInformation: {
      title: "输入接收器数据",
      receiverName: "收货人姓名",
      helpText: "请在此处填写所有将接收产品的人的信息",
      saveContact: "保存联系人",
      cpfOrCnpjHelpText:
        "用于向目的地国家/地区海关识别客户身份的税号。 在 {country}，此号码称为 {documentName}，并具有以下格式：{documentFormat}",
      postalCodeHelpText:
        "客户所在地的邮政编码。 在 {country} 中，该数字具有以下格式：{cepFormat}",
      customerPhoneHelpText: "Only the phones from {country} are accepted",
      customeraddressHelpText: "仅地址街道、大道、地点, ...",
      customeraddressnumberHelpText:
        "只有街道号码。 如果包含字母（例如123A），则在此输入数字，在地址补码字段中输入字母",
      customeraddresscomplementHelpText:
        "街道号码、建筑物名称、楼层、公寓、门、邮箱的附加信息, ...",
      customeraddressreferenceHelpText:
        "帮助邮递员的参考点（例如在面包店后面）",
      rfbCodeHelpText: "当订单为 PRC 时，RFB 代码为必填项",
      sellertaxnumberHelpText:
        "用于向目的地国家/地区海关识别发件人身份的税号。 有时称为增值税",
      sellerphoneHelpText: "请传递国家/地区代码和“+”（例如+55991888282）",
      selleraddressHelpText: "仅地址街道、大道、地点, ...",
      selleraddressnumberHelpText:
        "只有街道号码。 如果包含字母（例如123A），则在此输入数字，在地址补码字段中输入字母",
      selleraddresscomplementHelpText:
        "街道号码、建筑物名称、楼层、公寓、门、邮箱的附加信息, ...",
      remetenteiscpfHelptext:
        "如果税号是真实的人的，请选中此框。 如果是公司的，请不要勾选此项",
      phoneSameCountry: "收件人电话所在国家/地区必须与收货人所在国家/地区相同",
      customeraddressPlaceholder: "Victory Avenue street",
      customeraddressnumberPlaceholder: "1234",
      customeraddresscopmlementPlaceholder: "Apartment 5, 17th floor",
      customeraddressreferencePlaceholder: "面包店后面",
      sellerphonePlaceholder: "+17728991919",
      sellerpostalcodePlaceholder: "CA-1234",
      sellertaxnumberPlaceholder: "1234567890",
      selleraddressPlaceholder: "Victory Avenue street",
      selleraddressnumberPlaceholder: "1234",
      selleraddresscomplementPlaceholder: "Tower A, 6th floor",
      sellerwebsitePlaceholder: "www.seller.com",
      sellerLogoUrlPlaceholder: "https://www.seller-site.com/images/logo.png",
    },
    originInformation: {
      title: "產地信息",
      helpText: "在此，请填写寄送产品的所有信息",
      buttonCompanyInfo: "填寫我的公司信息",
      infoPhone: "包括國家代碼。 例如：+55 22 22222222",
    },
    shipmentInformation: {
      title: "请告知要发送的产品数据",
      helpText: "请在此填写有关产品和包装盒的所有信息以及一般信息",
      landedCost: "含稅成本",
      PRC: "合规运输 (PRC)",
      landedCostWarning: "必需的",
      PrcWarning: "必需的",
      includeInsuranceNameHelpText: "如果您想添加保险，请勾选此框",
      ordernumberHelpText: "唯一编号，供您在所有订单中识别该订单",
      saleschannelHelpText: "向您收取订单的销售渠道",
      saleschannelordernumberHelpText: "二级订单号，供您自己使用",
      incotermHelpText: "指示谁将支付客户 (DDU) 或发件人 (DDP) 的关税",
      landedcostHelpText: "The item prices include all the taxes",
      currencyHelpText: "下面告知的所有价格值都将被视为以该货币计算",
      taxesCurrencyHelpText: "ICMS 和 II 税的价格价值",
      itemdescriptionHelpText:
        "物品的详细描述，应尽可能全面，包括标记和型号，因为这些信息将用于报关",
      itemnameHelpText: "物品的简短描述，供您自己使用",
      itemhscodeHelpText: "协调关税代码，6 或 8 位数字标识产品的性质",
      itemskuHelpText: "供您自己使用",
      itemboxHelpText:
        "指示该商品将被放入哪个订单框中。 如果您只使用一个框，只需输入“1”",
      boxemptyweightHelpText: "仅空箱重量，不含任何产品",
      USBcharger: "USB charger",
      productDescriptionPlaceholder:
        "USB 迷你充电器 2500 mA 索尼 - 型号 MXS2009",
      knowYouCan: "你知道吗你可以",
      registerBoxes: "注册您的盒子",
    },
    courierInformation: {
      title: "选择物流商",
      helpText: "在这里，请选择快递并填写最后几条信息",
      fillAllOrderInfo: "询问报价前请填写所有订单信息",
      changeToDdu: "由于所选合同及物值，请选择DDU",
      changeToDdp: "由于所选合同，请选择DDP",
      tooLowtoCover: "物质过低，无法计算邮费和税费",
      agreeSummary: "我同意此费用汇总表（必选）",
      costSummary: "费用汇总",
      selectedContract: "选定合约",
      getQuote: "获取报价",
      newContract: "注册新合作伙伴",
      lastmilenumberHelpText: "我们应使用与我们的合作伙伴跟踪订单的号码",
      dutiestaxHelpText: "产品和运费的国家进口税",
      salestaxHelpText: "销售的国家税",
      freightvalueHelpText:
        "将有效申报给海关并出现在所有法律文件上的运费。您对这条信息负责，我们强烈建议使用与运费成本字段中相同的值。",
      freightcostHelpText:
        "由我们的系统计算的运费，对应于您将为此订单支付的{val}金额",
      lastMileNumberPlaceholder: "NX123456789",
      fields: {
        item: "盒子/小件",
        quantity: "数量",
        taxed: "征税单件",
        subtotalTaxed: "征税小计",
        untaxedDollar: "未征税单件",
        subtotalUntaxed: "未征税小计",
      },
      fieldsSummary: {
        totalProductsValue: "全部税费",
        freightValue: "运费成本",
        totalDeclaredValue: "申报价值",
        taxes: "税费",
        totalValueWithTaxes: "总值（含税）",
      },
      quoteHelpTexts: {
        freightCost:
          "由我们的系统计算的运费，对应于您将为此订单支付的{val}金额",
        dutiesTax: "产品和运费的国家进口税",
        salesTax: "销售的国家税",
        totalCost: "您需要支付的总金额，包括运费和（仅限DDP）税费",
        deliveryTime: "从订单创建到最终交付给客户的预计时间",
      },
    },
    footer: {
      howCreateOrder: "精益如何创建订单",
    },
    overviewInformation: {
      title: "订单概述",
      moreInfo: "更多信息",
    },
    stepsCardDetail: {
      stepOne: "订单数据",
      stepTwo: "检查",
      stepThree: "摘要",
      stepFour: "标签和备注",
      stepFive: "发货",
      stepSix: "完成",
    },
    titleColumns: {
      boxProducts: "箱子和产品",
      carrier: "承运人",
      estimatedDelivery: "预计交付",
      totalPartial: "总计部分",
    },
    orderSummary: "订单摘要",
  },
  ordersRejectedPage: {
    title: "无效订单",
    dateImport: "导入日期",
    content: "内容",
    error: "错误",
  },
  dutiesAndTaxesPage: {
    title: "关税和税款",
    orderStatus: "订单状态",
    status: {
      new: "新的",
      solved: "解决了",
      inProgress: "进行中",
      waitingForPayment: "等待付款",
      priority: "优先事项",
      needSelllerAction: "需要卖家采取行动",
      received: "已收到",
      sentAgain: "再次发送",
      paymentDone: "付款完成",
      pending: "待办的",
      paidExternal: "付费外部",
    },
  },
  DutiesAndTaxesViewPage: {
    generalInformation: "一般信息",
    taxesInformation: "税务信息",
    customerInformation: "客户信息",
    creationDate: "小包创建时间",
    orderNumber: "订单号",
    customerName: "客户姓名",
    customerCountry: "客户国家",
    tracking: "后程单号",
    incoterm: "Incoterm",
    estimatedTax: "收取税费",
    realTax: "实际税费",
    payment: "支付状态",
    message: "信息",
    expectedTax: "预估税值",
    productsValue: "货物价值",
    paymentCertificate: "付款证明",
  },
  orderViewPage: {
    title: "订单号",
    generateFirstMileLabel: "生成第一英里标签",
    generateLastMileLagel: "生成最后一英里标签",
    documentsLabel: "文件和标签",
    suggestedAction: "建议操作",
    orderData: "订单数据",
    shippingInformation: "运送信息",
    customerInformation: "客户信息",
    generateFMLabel: "生成第一英里标签",
    generateBoxLabel: "生成箱子标签",
    generateLMTN: "生成最后一英里标签",
    generateInvoice: "生成发票",
    boxData: "箱子数据",
    trackingDetails: "更多物流信息",
    orderNumber: "订单号",
    newOrderNumber: "新订单号",
    orderNumberMustBeUnique: "订单号不可重复",
    observations: "备注",
    shipperConfirmation: "航班信息",
    shipping: "装箱中",
    incoterm: "Incoterm",
    type: "类型",
    standard: "标准",
    haveInsurance: "含保险",
    overpackReference: "外包物流号",
    shipFrom: "来源地",
    totalVolumes: "全部小包",
    volume: "小包",
    currentStatus: "现状态",
    trackingHistory: "物流历史",
    carrierOrShipper: "物流商 / 航班",
    trackingStatus: "物流状态",
    message: "信息",
    attachment: "附属文件",
    addStatus: "添加状态",
    statusToAdd: "待添加状态",
    editOrder: "编辑订单",
    cloneOrder: "克隆订单",
    deleteOrder: "删除订单",
    isReversable: "该合约不允许创建反向交易量",
    orderVolumes: "订单量",
    createVolumeReverse: "创建折返小包",
    volumesInReverse: "该小包已经在折返进程中",
    noVolumes: "这些小包已全部折返",
    noReversedVolumes: "无小包折返",
    deleteConfirm: "确定删除订单?",
    fieldsOrderCosts: {
      freight_cost: "运费",
      duties: "职责",
      insurance: "保险",
      general_costs: "一般费用",
      fines: "罚款",
    },
    fieldsVolumeItems: {
      unitProducts: "单位产品",
      unitFreight: "单位运费",
      unitTax: "单位税",
    },
  },
  overpacksListPage: {
    title: "外包裝狀態",
    found: "未找到外包 | 外包 | 外包 ",
    readyToDispatch: "可装舱",
    downloadOverpacks: "下载外包信息",
    actions: "操作",
    updateFirstMileInformations: "更新首程物流信息",
    deleteOverpacks: "删除外包",
    setOverpackAs: "设置外包为",
    dispatchOverpack: "外包装舱",
    generateLabel: "合成标签",
    carrierName: "物流商名",
    overpackHasNotDispatched: "所选外包中一外包尚未装舱",
    OverpackHasAlreadyDispatched: "所选外包中一外包已经装舱",
    trackingNumber: "追蹤號碼",
    generateLabelAndInvoice: "產生標籤和發票",
  },
  overpackViewPage: {
    title: "外包补充编号",
    dispatchOverpack: "外包装舱",
    countryDestination: "目的国家",
    totalVolumes: "全部小包",
    totalOrders: "全部订单",
    totalOrdersValue: "全部订单金额总计",
    totalWeight: "总重",
    contractName: "合同名",
    dispatchDate: "装舱日期",
    shipFrom: "来源地",
    originHub: "来源港口Hub",
    addOder: "添加订单",
    noOrderToOverpack: "外包内无有效订单",
    carrierName: "物流商",
    noCarrier: "“机场到门”合同没有运费",
  },
  groupConfigurationPage: {
    title: "设置",
    secondaryTitle: "消息",
    configuration: "设置",
    group: "分组",
    groups: "分组",
    menu: "Menu",
    active: "可用",
    inactive: "不可用",
    createConfiguration: "创建设置",
    deleteConfiguration: "删除设置",
    addGroup: "添加分组",
    updateGroup: "更新分组",
    deleteGroup: "删除分组",
    groupInformation: "分组信息",
    choosenSalesChannels: "已选销售渠道",
    salesChannelsWithoutGroups: "未分组销售渠道",
    noChoosenSalesChannels: "未选择销售渠道",
    noSalesChannelsWithoutGroups: "无未分组销售渠道",
  },
  createGroupConfigurationPage: {
    title: "创建设置",
    countries: "国家",
    customer: "客户",
    hide: "隐藏",
    checkpoints: "状态",
    addConfiguration: "添加设置",
    showSeller: "显示卖家",
    showSalesChannels: "显示销售渠道",
    chosenCountries: "已选国家",
    newMenu: "新菜单",
    existingMenu: "现有菜单",
  },
  groupConfigurationEditPage: {
    title: "修改设置",
    discardChanges: "丢弃更改",
    leaveWithoutSaving: "离开而丢弃草稿",
    leave: "离开",
    stay: "取消",
  },
  groupConfigurationView: {
    title: "设置",
  },
  trackingPageSettingsPage: {
    title: "物流页面设置",
    firstText:
      "为您的跟踪页面打造品牌，以便客户在查询订单物流状态时获得完美体验。",
    secondText: "您的信息可以随时编辑",
    simulateYourTrackingPage: "模拟物流页面",
    showLogo: "显示logo",
    showContractInfo: "显示合同信息",
    textShowContractInfo: "网站、社交媒体、电子邮件和电话号码。",
    displayAdvertising: "显示广告",
    textDisplayAdvertising: "建议尺寸：1200 x 630 -不可小于该尺寸",
    uploadedImage: "上传图片",
    displayFootnote: "显示脚注",
    footnote: "脚注",
    footnotePlaceholder: "感谢您的支持",
    settingsUpdated: "设置已更新",
    trackingPageSimulation: "物流页面模拟",
    orderNumber: "订单号",
    estimatedDelivery: "预计签收",
    latestStatus: "最新动态",
    dateExample: "August 23rd 2020 ",
    orderHasBeenCreated: "订单已创建",
    shippingInformation: "航班信息",
    correiosStandard: "CORREIOS Standard",
    trackingNumber: "物流单号",
    socialLinksLabel: "社交链接标签",
    colorSchemeLabel: "颜色方案",
  },
  trackingSettingsPage: {
    title: "追蹤頁面",
    subtitle: "自訂追蹤頁面以符合您的公司",
    trackingPagePreview: "追蹤頁面預覽",
    viewInFullScreen: "全螢幕檢視頁面",
    colors: {
      title: "配色方案",
      subtitle: "選擇頁面配色方案",
      foregroundColor: "前景色",
      backgroundColor: "背景顏色",
      text_color: "标题文字颜色",
      returnsToDefaultColors: "回傳預設顏色",
    },
    brand: {
      title: "顯示品牌",
      subtitle: "到另一張圖片",
      changeBrandIn: "更改品牌",
      thereIsNoRegisteredBrand: "如果沒有註冊品牌會怎麼樣？",
      insertCompanyName: "輸入公司名稱",
      insertOtherText: "插入其他文本",
      insertOtherTextPlaceholder: "其他名字",
      onClickBrand: "當您點擊公司品牌時會發生什麼？",
      forwardToCompanyWebsite: "轉寄至公司網站",
      brandIsNotClickable: "任何事物。 該品牌不可點選。",
    },
    contacts: {
      title: "顯示聯絡資訊",
      subtitle: "定義要顯示的聯絡資訊。",
      selectSocialNetwork: "選擇您想要顯示的社交網絡",
      contactUsClick: "選擇點擊“聯絡我們”後會發生什麼",
      sendMailTo: "發送郵件至",
      forwartToWebsite: "轉寄至網站",
    },
    ad: {
      title: "添加 添加",
      subtitle: "新增有關您公司的廣告。",
      fileDropLabel: "在廣告中插入圖片（建議：932 像素 x 240 像素）",
      chooseImage: "點擊或將您的圖像（.jpeg 或 .png）拖至此處",
      mainText: "正文（如果留空，則不會出現）",
      linkText: "連結文字（如果留空，則不會出現）",
      linkTextPlaceholder: "立即了解",
      bannerLink: "橫幅連結",
    },
    footer: {
      title: "頁尾",
      subtitle: "定義要插入頁尾的內容",
      footnote: "註腳",
      onClickAction: "點擊頁腳中的文本，您將進入該公司的網站。",
    },
  },
  settingsPage: {
    title: "消息设置",
    sendFromEmail: "邮件发送",
    emailConfirmed: "电子邮件已确认",
    domainVerified: "域名已验证",
    howtoVerifyDomain: "如何验证域名",
    textHowtoVerifyDomain1: "验证您的域名",
    textHowtoVerifyDomain2: "转到您的 DNS 提供商并添加包含以下值的 TXT 记录:",
    textHowtoVerifyDomain3: "这将使我们能够从您的域发送电子邮件.",
    textResendVerificationEmail: "重新发送验证邮件",
    textYouWillSendVerificationEmail: "您将重新发送一封验证电子邮件至",
    textSendFromEmail:
      "我们将您的电子邮件地址（默认您的公司资料中填写的电子邮件）发送物流通知",
    sendFromSms: "短信发送",
    textSendFromSms:
      "默认从我司Nobordist的电话号码发送短信，如需更改，请联系销售代表，短信沟通额外收费",
    sendFromWhatsapp: "Whatsapp发送",
    textSendFromWhatsapp:
      "默认从我司Nobordist的电话号码的Whats发送短信，如需更改，请联系销售代表，whatsapp额外收费",
    sendFromWebhook: "Webhook发送",
    textSendFromWebhook:
      "您可以通过创建webhook发送通知，我司会将JSON推送到给定的URL",
    webhook: "Webhook",
    url: "Url",
    key: "Authentication key",
    header: "Authentication header name",
    addWebhookUrl: "添加网页链接",
    disabling: "停用中",
    enabling: "启用中",
    editDomain: "编辑域名",
    notificationEmail: "邮件发送消息",
    noPublicEmail: "禁止来自 yahoo、gmail、outlook 等公共域的电子邮件",
    textWarnCostsSms:
      "可选择在Nobordist平台发送信息，这会产生额外费用。请确认您同意支付这些额外费用",
    textWarnCostsWhats:
      "可选择WhatsApp，这会产生额外费用，而使用Facebook验证模板有额外附加付费，请确认您同意支付这些额外费用！",
  },
  settingsViewPage: {
    title: "用户信息",
    warning: "删除用户信息",
  },
  settingsEditPage: {
    title: "更新用户信息",
  },
  quickStartPage: {
    title: "快速创建设置",
    firstText:
      "定义快速创建的相关设置以自动预填报价、订单创建、订单导入和产品导入中的信息",
    newConfiguration: "添加设置",
    add: "添加",
    pleaseSelectOne: "请选择至少一项",
    done: "成功",
    configurationAppliedTo: "配置应用于",
    noConfigApplied: "无配置应用于此页",
    modify: "更改",
    confirmChanges: "确认保存？",
    confirmDelete: "确认删除？",
    deleteConfiguration: "删除",
    unsavedData: "未报存",
    saveUnsavedOrCancel: "请保存信息或取消",
    ok: "Ok",
    productCategory: "产品品类",
  },
  advancedPage: {
    title: "高级设置",
    subTitle: "定義您想要使用的單位制",
    fistText: "在此编辑高级设置",
    weightsMeasures: "重量和尺寸",
    weightSystem: "重量系統",
    settingsAppliedAll:
      "當您透過 API、進口或直接在平台建立訂單時，所有措施都必須以這些單位傳遞。 網站和 API 中的所有措施都將以這些單位顯示",
    changePlatformLanguage: "更改平台語言",
    measurementSystem: "测量单位",
    dispatches: "派发",
    dispatchesHelpText:
      "要開始對訂單進行操作，您需要對其進行派發（點擊「派發」或透過 API 進行派發。在這裡，您可以自動化此派發。訂單建立後將自動派發。注意：一旦派發，您將無法再編輯或刪除它",
    autoSingle: "建立後自動派發訂單",
    cpfValidation: "CPF 验证",
    allowsCpfValidation: "在訂單建立期間強制對客戶 CPF 進行嚴格驗證",
    cpfHelpText:
      "對於發送到巴西的每個訂單，我們都會驗證稅號格式 (CPF) 是否有效，如果無效則傳回錯誤。 在這裡，您可以設定一些額外的驗證：我們將系統地向巴西國稅局 (Receita Federal) 核實客戶是否有權接收來自國外的包裹，以及通過的稅號是否有效且屬於他們。 如果出現問題，我們將傳回錯誤訊息此設定可能會導致額外費用",
  },
  boxesProductsPage: {
    title: "盒子和产品",
    subTitle: "浏览以下选项卡以配置案例和产品模板",
    boxModels: "盒子模板",
    productModels: "产品模板",
  },
  boxesPage: {
    title: "我的包装盒",
    subTitle:
      "在此页面上，您可以注册预定义的盒子模型，以便在创建订单时重复使用。 要注册新模型，请单击“添加框”",
    found: "无包装盒 | 包装盒 | 包装盒",
    autoSelect: "自动选择",
    autoSelectToolTip: "默认情况下，创建订单时将选用自动选择的包装盒。",
    auto: "自动",
    addBox: "添加包装盒",
    noBoxFound: "你尚未添加包装盒",
    createNewBox: "创建新包装盒",
    newBoxTemplate: "新框模板",
    updateBox: "更新盒子信息",
    editBox: "Edit Box",
  },
  printingsPage: {
    title: "打印设置",
    standardConfig: "在此定义打印文档的选项",
    documentFormat: "文件格式与设置",
    commercialInvoice: "账单",
    baterryConfirmation: "电池内容物确认书",
    ifDangerousPersonal: "危险品标签联系方式",
    packingSlip: "装箱单",
    dangerousGood: "危险品",
    msds: "MSDS",
    shippingLabel: "电池内容物确认书",
    shippingLabelSubtitle: "这是将贴在您的盒子上的标签",
    commercialInvoiceSubtitle: "进口商与出口商之间的购销交易文件",
    packingSlipLabelSubtitle: "列出装运中包含的产品",
    batterySubtitle: "货物包含电池时所需的授权文件",
    dangerousGoodSubtitle: "授权列出货物中的危险文件",
    msdsSubtitle: "材料安全数据表",
    includeApendixLabel: "包含附件标签",
    overpackLabel: "外包标签",
    devolutionAddress: "退货地址",
    userDevolutionAddress: "用户个人退货地址",
    //options
    letterSizeFull: "A4",
    letterSizeHalf: "A8",
    thermalPrinter: '"4x6” 标签',
    //messages
    saveSuccess: "标签打印设置成功",
    saveTryLater: "无法保存，请重新尝试",
    switchHelpText: "包括在打印全部",
    devolutionCustomHelpText: "适用于所有文件",
    ifYouPrefer: "如果愿意，",
    theTrackingPage: "您还可以自定义跟踪页面。",
    documentExample: "文档示例",
    wantToLearnAboutLabels: "想了解有关标签和文件的所有信息吗",
  },
  salesChannelsPage: {
    title: "销售渠道设置",
    found: "未找到销售渠道 | 销售渠道 | 销售渠道 ",
    addSalesChannel: "添加销售渠道",
    newSalesChannel: "新的销售渠道",
    updateSalesChannel: "更新销售渠道",
    createSalesChannel: "创建新销售渠道",
    salesChannelInfo: "销售渠道信息",
  },
  productsPage: {
    title: "我的产品",
    subTitle:
      "在此页面上，您可以注册产品模型以在创建订单时重复使用。 要注册新型号，请选择“添加产品”中的可用选项之一",
    newProductTemplate: "新产品模板",
    found: "未找到产品 | 产品 | 产品",
    addProduct: "添加产品",
    downloadProducts: "下载产品",
    deleteProduct: "删除产品",
    selectHowAddProducts: "选择添加产品方式",
    manual: "手动输入",
    import: "文件导入",
    browseFilesToUpload: "浏览文件",
    selectCsv: "选择csv文件上次",
    downloadHere: "下载",
    csvFile: "CSV文件模板",
    createProductTitle: "完善信息以添加产品",
    updateProduct: "更新产品信息",
    skuHelptext: "识别您产品的唯一代码",
    nameHelptext: "产品的简短描述（例如白鞋）仅供您自己内部使用",
    descriptionHelptext: "用于报关。 请尽可能具体，包括产品的标记和型号",
    categoryHelptext:
      "产品类别。 HsCode 将根据此选择自动填写。 如果您想设置特定的HS编码，请使用“其他”",
    hs_codeHelptext:
      "识别产品的 6 或 8 位财务代码。 请参阅协调制度 (HS) 代码了解更多信息",
    dimensionsHelptext: "尺寸遵循以下格式：宽 x 高 x 长",
  },
  userListPage: {
    title: "用户总览",
    found: "未找到用户 | 用户 | 用户",
    addUser: "添加用户",
    addUserTitle: "创建新用户",
  },
  userCreatePage: {
    title: "创建新账号",
    create: "创建",
    account: "账号",
    companyInformation: "企业信息",
  },
  customersPage: {
    title: "我的客戶",
    subtitle: "用戶管理。",
    subtitle2: "如果您願意，可以點擊客戶姓名進行編輯並查看其他操作。",
    found: "未找到客户 | 客户 | 客户",
    newCustomer: "新客戶",
    addCustomer: "添加客户",
    downloadCustomers: "下载客户信息",
    deleteCustomer: "删除客户",
    selectHowAddCustomer: "选择添加方式",
    browseFilesToUpload: "上传文件",
    addCustomerTitle: "手动填写信息",
    documentTypeCaption: "仅巴西地区需要税号",
    onlyNumberCaption: "仅号码",
    addressQuarter: "地址街区",
    addressReference: "地标",
    updateCustomer: "更新客户信息",
    customerData: "客戶資訊",
    importingFile: "導入文件",
    importedFile: "導入的文件",
    failedToImportFile: "導入文件失敗",
  },
  documentsPage: {
    title: "文件",
    subtitle: "檢查預先註冊的文件並添加您已有的文件，連結到類別和 HS 代碼。",
    documentName: "文件名稱",
    newDocument: "新文件",
    addDocument: "新增文件",
  },
  insurancePace: {
    title: "保险",
    subtitle: "选择保险计算方式",
    insuranceValue: "运输品类",
    insuranceValueCaption: "保险是否包含运费",
    choosHowInsuranceShouldBeCalculated: "选择保险计算方式",
    useSellingPrice: "使用销售价格",
    alwaysInsure: "使用固定价格",
    use: "使用销售价格的固定百分比",
    ofSellingPrice: "售价",
    includeShippingCost: "是否包含运费",
    yesShipCoveredClain: "是的，包含运费（推荐）。",
    noCoverItemOnly: "否，仅货物价值",
    calculationBase: "计算依据",
    calculationBaseHelpText: "详细信息注释。",
    freightCoverageHelpText: "详细信息注释。",
  },
  integrationsPage: {
    title: "集成",
    firstText: "连接到您的其他帐户以管理您在 Nobordist 平台上的所有订单",
    connectMabang: "连接到 MABANG ERP",
    getToken: "获取token",
    getTokenCaption: "点击复制token",
    getTokenText: "获取你的token来将你的马帮账户连接到Nobordist平台",
    getTokenMoreInfo: "更多信息请查看教程",
    dhlTitle: "连接DHL账户",
    dhlText: "您需要有DHL Express帐户和开发人员的口令密码才能填写此表格",
    dhlOkButton: "连接DHL",
    anjunTitle: "连接您的 Anjun 账户。",
    anjunText: "您需要一个现有的 Anjun 账户和开发者凭证来完成此表格。",
    anjunOkButton: "连接至 Anjun",
    csatTitle: "连接您的 CSAT 账户。",
    csatText: "您需要一个现有的 CSAT 账户和开发者凭证来完成此表格。",
    csatOkButton: "连接至 CSAT",
    totalTitle: "连接您的 Total Express 账户。",
    totalText: "您需要一个现有的 Total Express 账户和开发者凭证来完成此表格。",
    totalOkButton: "连接至 Total Express",
    bringerTitle: "连接您的 Bringer 账户。",
    bringerText: "您需要一个现有的 Bringer 账户和开发者凭证来完成此表格。",
    bringerOkButton: "连接至 Bringer",
    hercoTitle: "连接您的 Herco 账户。",
    hercoText: "您需要一个现有的 Herco 账户和开发者凭证来完成此表格。",
    hercoOkButton: "连接至 Herco",
    kparcelTitle: "连接您的 K-PARCEL 账户。",
    kparcelText: "您需要一个现有的 K-PARCEL 账户和开发者凭证来完成此表格。",
    kparcelOkButton: "连接至 K-PARCEL",
    mabangTitle: "连接您的 MABANG 账户。",
    mabangText: "您需要一个现有的 MABANG 账户和开发者凭证来完成此表格。",
    mabangOkButton: "连接至 MABANG",
    sinerlogTitle: "连接您的 SINERLOG 账户。",
    sinerlogText: "您需要一个现有的 SINERLOG 账户和开发者凭证来完成此表格。",
    sinerlogOkButton: "连接至 SINERLOG",
    skypostalTitle: "连接您的 Sky Postal 账户。",
    skypostalText: "您需要一个现有的 Sky Postal 账户和开发者凭证来完成此表格。",
    skypostalOkButton: "连接至 Sky Postal",
    bilTitle: "连接您的 Bil 账户。",
    bilText: "您需要一个现有的 Bil 账户和开发者凭证来完成此表格。",
    bilOkButton: "连接至 Bil",
    correiosTitle: "连接Correios账户",
    correiosText:
      "您需要有Correios Express帐户和开发人员的口令密码才能填写此表格",
    correiosOkButton: "连接Correios",
    reverse: " Reverse",
    fedexTitle: "连接Fedex账户",
    fedexText: "您需要有Fedex Express帐户和开发人员的口令密码才能填写此表格",
    fedexOkButton: "连接Fedex",
    shipstationTitle: "连接Shipstation账户",
    shipstationText:
      "您需要有Shipstation Express帐户和开发人员的口令密码才能填写此表格",
    shipstationOkButton: "连接Shipstation",
    shopifyTitle: "连接Shopify账户",
    shopifyText:
      "您需要有Shopify Express帐户和开发人员的口令密码才能填写此表格",
    shopifyOkButton: "连接到 Shopify",
    upsTitle: "连接Ups账号",
    upsText: "您需要有Ups Express帐户和开发人员的口令密码才能填写此表格",
    upsOkButton: "连接Ups",
    vtexTitle: "连接Vtex账户.",
    vtexText: "您需要有的Vtex Express帐户和开发人员的口令密码才能填写此表格",
    vtexOkButton: "连接Vtex",
    lionTitle: "连接您的 360 Lion 帐户.",
    lionText: "您需要现有的 360 Lion 帐户和开发者凭据才能填写此表格.",
    lionOkButton: "连接到 360 Lion",
    mailAmericasTitle: "连接您的 Mail America 帐户.",
    mailAmericasText:
      "您需要现有的 Mail Americas Express 帐户和开发人员凭据才能填写此表格.",
    mailAmericasOkButton: "连接到 Mail America",
    br1ExpressTitle: "连接您的 BR1 Express 帐户.",
    br1ExpressText:
      "您需要一个现有的 BR1 Express Express 帐户和开发人员凭据才能填写此表格.",
    br1ExpressOkButton: "连接到 BR1 Express",
    accNick: "账户名",
    accPass: "密码",
    accNumber: "账号",
    siteId: "站点 ID",
    user: "用户",
    admCode: "Admin号",
    contract: "合同",
    name: "姓名",
    carrier: "载体",
    cooperation: "合作",
    price_table: "价格表",
    delivery_time: "交货时间",
    key: "认证密钥",
    value: "价值",
    senha: "密码",
    usuario: "用户",
    loginHash: "登录",
    cartaoPostagem: "明信片",
    authorization: "授权",
    token: "代币",
    line_code: "线路代码",
    remetenteId: "发件人ID",
    externalCustomerId: "外部客户ID",
    externalReferenceCode: "外部参考代码",
    dispatchServiceCode: "调度服务代码",
    shipperAccountNumber: "发货人账号",
    billingAccountNumber: "结算账号",
    channelCode: "渠道代码",
    userCode: "用户代码",
    userKey: "用户密钥",
    copaId: "Copa编号",
    boxId: "箱子编号",
    firstMileServiceCode: "首英里服务代码",
    serviceCode: "服务代码",
    userId: "用户ID",
    logisticsServerToken: "物流服务器令牌",
    contractDeleted: "合同已删除",
    contractNotdeleted: "合同无法删除！",
    contractNotCreated: "连接无法创建，请稍后重试",
  },
  onboardPage: {
    title: "欢迎加入Nobordist <b>{val}</b> ！",
    linkText: "更多信息请查看教程",
    quickStart: "快速创建",
    quickStartText:
      "定义快速创建的相关设置以自动预填报价、订单创建、订单导入和产品导入中的信息",
    quickStartBtn: "快速创建",
    order: "订单",
    orderText: "通过接口或文件导入创建订单",
    orderBtn: "创建新订单",
    printing: "打印设置",
    printingText: "请定义文档打印选项的标准配置",
    printingBtn: "打印设置",
    connectSellingChannel: "连接销售渠道",
    connectSellingChannelText:
      "连接销售渠道，我们将整合您的所有订单以简化订单管理",
    connectSellingChannelBtn: "连接销售渠道",
    notification: "消息",
    notificationText: "采用智能通信改善客户售后体验",
    notificationBtn: "消息",
  },
  insightsPage: {
    volumesByRegion: "小包地区",
    shippedVolumes: "已装舱小包",
    customerEngagement: {
      pannel: "面板",
    },
    customerOverview: {
      topFiveCustomers: "前五名大客户",
      orderByBuyer: "买家所在州订单",
    },
    overview: {
      volumeStatus: "小包状态",
    },
    salesTrend: {
      sales: "销售",
      topSellingProducts: "热销产品",
      salesByCategory: "品类销售信息",
      salesBySalesChannel: "销售渠道销售信息",
    },
  },
  companyPage: {
    title: "我的公司",
    subTitle: "检查并更新（如有必要）您的公司信息",
    generalData: "General Data",
    brandSignature: "Brand and signature",
    legalName: "公司名",
    rfbCode: "RFB Code",
    thisInfoWllBeUsed: "此信息将用于您的品牌宣传",
    addSignature: "添加签名",
    showSignature: "显示签名",
    addLogo: "添加logo",
    companyInformation: "企业信息",
    companyName: "企业名",
    companyID: "内部Nobordist参考",
    checkBrandTab: "此字段可在品牌和签名选项卡中更改",
    usedByNB: "Nobordist用作内部参考",
    customerID: "客户ID",
    websiteURL: "网页链接",
    taxNumber: "税号",
    authorizeText: "我授权在必要运输文件上使用此签名",
    devolutionAddress: "退货地址",
    devolutionAddressSubtitle: "问题未解决的订单将被退回至此地址",
    ownDevolutionAddress: "使用此地址作为返回地址",
    editCompany: "编辑企业信息",
    addressComplement: "地址备注",
    sellerAddress: "賣家地址",
    addSellerAddress: "添加賣家地址",
    noSellerAddressFound: "您還沒有任何註冊地址。",
    createNewAddSellerAddress: "創建一個新地址",
    found: "找不到賣家地址 | 賣家地址 | 賣家地址",
    editAddSellerAddress: "更新地址信息",
    generalDataPage: {
      subTitle: "如有必要，请检查和编辑公司数据",
      companySubTitle: "如有必要，请检查和编辑公司数据",
      companyAddress: "公司地址",
      companyAddressSubTitle: "这是公司地址",
    },
    brandSignaturePage: {
      visualTitle: "视觉身份",
      visualSubTitle: "配置公司颜色、品牌和签名",
      signatureTitle: "签名",
      signatureSubTitle: "配置出现在公司文件上的签名",
      noImageAdded: "未添加图像",
      clickOrDrag: "单击或拖动您的图像至此处",
      companyBrand: "公司品牌",
      companySignature: "公司签名",
      recomendedWidth: "建议：水平，宽度180像素，PNG格式",
      companyColors: "公司颜色",
      reflectOn: "将反映在",
      and: "和",
      foregroundColor: "前景色",
      backgroundColor: "背景色",
      textColor: "文字颜色",
      returnTo: "返回 ",
      defaultColors: "默认颜色",
      authUse: "授权使用",
    },
  },
  error404Page: {
    title: "未找到运单号!",
    tryAgain: "重新尝试",
  },
  homePage: {
    subtitle: "今天你想做什麼？",
    integrateEcommerce: "整合電商",
    quotation: "引述",
    newQuotation: "新報價",
    newOrder: "新指令",
    addOrders: "新增訂單",
    addOrdersManually: "手動新增訂單。",
    simulateYourDelivery: "模擬您的交付",
    uploadMultipleOrders: "上傳多個訂單",
    addYourFavoriteStores: "添加您最喜歡的商店",
    connectEcommerce: "連接電商",
    freightValue: "運費價值",
    freightCost: "運費",
    averageOrderCost: "平均訂單成本",
    rejectedOrders: "拒絕訂單",
    createdOrders: "已建立訂單",
    totalAwaitingYourAction: "{total} 個訂單等待您的操作",
    totalAwaitingPickup: "{total} 個訂單等待取貨",
    totalShippedOrders: "{total} 已出貨訂單",
    last30Days: "過去 30 天",
  },
  loginPage: {
    login: "登入",
    welcome: "欢迎",
    pleaseYourAcc: "请输入您的帐户",
    forgotPassword: "忘记密码",
    notRegisteredYet: "尚未注册",
    checkOur: "看看我们的",
    generalConditionsService: "综合服务条件",
    resetPasswordSent: "重置密码邮件已发送，请于邮箱查看",
    sendResetPasswordInstructions: "充值密码介绍",
    iAgree: "同意",
    agreementText:
      "通过勾选此框，您承认您已阅读并同意我们的一般服务条款受本协议。",
    createYourAccount: "创建您的帐户",
    fillInfoToCreateAcc: "填写以下字段以创建您的帐户",
    userData: "用户数据",
    pleaseYourData: "请填写您的资料",
    companyData: "公司资料",
    pleaseCompanyData: "请填写公司资料",
    hasRegistration: "已经登记了",
    accessMyAcc: "访问我的帐户",
    needHelp: "需要帮忙",
    talkWithOur: "与我们的一位顾问交谈",
    ifCheckOut: "如果您愿意，请查看我们的",
  },
  passwordNewPage: {
    title: "充值密码",
    registeredEmail: "登记邮箱",
    newPassword: "新密码",
    confirmNewPassword: "再次新密码",
    passwordDosentMatch: "两次输入密码不符",
    passwordChanged: "密码已更改",
  },
  trackMyOrderPage: {
    title: "追踪航班",
    subtitle: "等待什么？ 立即追踪您的订单!",
    trackingNumber: "物流单号",
    placeholderTrackingNumber: "例子: BRBR771909457310",
    taxIdentificationNumber: "税号(CPF/CNPJ)",
    track: "追踪",
    enterTtrackingNumber: "填写物流单号",
    enterIdentificationNumber: "请填写正确税号",
    trackingNotFound: "未找到该物流单号",
    taxIdentificationNotFound: "错误身份信息",
  },
  trackMyOrderViewPage: {
    title: "追踪我的订单",
    contactUs: "联系我们",
    statusBar: {
      created: "已创建",
      shiped: "已发货",
      inTransit: "在途中",
      outOfDelivery: "已发货",
      delivery: "送货",
    },
    deliveredBby: "配送人",
    estimatedDelivery: "预计交货",
    destination: "目的地",
    fromNow: "现在起",
    days: "天",
    late: "晚的",
    latestStatus: "最新动态",
    lastMileTrackingNumber: "最后一英里追踪号码",
    expandOrderHistory: "展开订单历史",
    updateByEmail: "通过电子邮件更新",
    authEmail: "我授权包含此订单更新的电子邮件",
    shippingInformation: "航班信息",
    lastMilePartner: "后程配送",
  },
  userProfilePage: {
    title: "我的信息",
    firstText: "如有必要，检查并更新您的个人信息",
    checkAndEditUser: "如有必要，检查并编辑您的数据",
    loginInfo: "登录信息",
    checkAndEditLogin: "如有必要，编辑您用于访问平台的数据",
  },
  userProfileEditPage: {
    title: "更新我的信息",
    updatedText: "用户信息已更新",
  },
  downloadsPage: {
    title: "檔案",
    subtitle: "所有下载都集中在此页面",
    errors: "Errors",
    found: "无可下载文件 | 下载文件 | 下载文件",
    status: {
      failed: "失败",
      processing: "加载中",
      done: "完成",
      starting_upload: "开始上传",
      checking_csv_format: "检查 CSV 格式",
      checking_columns: "检查列",
      checking_equal_orders_volumes: "检查相同订单的体积",
      checking_cells: "检查单元格",
      validation_success: "验证成功！正在创建表格",
    },
    fields: {
      id: "ID",
      type: "类型",
      status: "状态",
      fileName: "文件名",
      creationDate: "创建日期",
      messages: "訊息",
    },
  },
  volumesReversePage: {
    title: "折返小包",
    viewTitle: "反向ID",
    reverseNumber: "反向号码",
    reverseVolume: "折返小包",
    reference: "追踪号码",
    salesChannelName: "销售渠道名称",
    volumeId: "体积ID",

    found: "无折返小包 | 折返小包 | 折返小包",
    fields: {
      id: "ID",
      volumeId: "小包ID",
      reverseNumber: "折返编号",
      addressComplement: "地址补充",
      orderItemsName: "小件名称",
      orderItemsDescription: "小件描述 ",
      orderItemsHsCode: "小件海关编码",
    },
    orderErroTicket: "订单生成电子票时出现错误",
    orderWaitingTicket: "等待创建电子票的订单",
  },
  myNotificationsPage: {
    found: "没有通知 | 通知 | 通知",
    readBtn: "标记为已读",
    notReadBtn: "标记为未读",
    viewAllNotifications: "所有通知",
    showAll: "显示所有",
    response: "回答",
    reply: "回复",
    fields: {
      title: "标题",
      body: "通知",
      created_at: "日期",
      isNew: "未读",
      isRead: "已读",
      type: "类型",
    },
    noNewNotifications: "没有未读通知",
    seconds: "秒",
    minuteAgo: "1 分钟前",
    minutes: "分钟",
    hourAgo: "1 小时前",
    hours: "小时",
    yesterday: "昨天",
    days: "天",
    lastWeek: "上周",
    weeks: "周",
    lastMonth: "上个月",
    months: "月",
    lastYear: "去年",
    years: "年",
  },
  myNotificationsListPage: {
    title: "通知",
    found: " 没有通知 | 通知 | 通知",
    filters: {
      isNew: "未读 / 已读",
    },
    allNotifications: "查看所有通知",
    view: "看法",
    youHaveNewNotification: "您有新的未讀通知。",
  },
  priceTablesPage: {
    titlePrice: "价格表",
    titleDelivery: "投递时间",
    importPricesTable: "进口价格表",
    importDeliveryTime: "进口交货时间",
    suretodelete: "您确定要删除吗",
    selectCsv: "选择要上传的 csv 文件",
    fileUploaded: "文件已上传",
    downloadHere: "在这里下载",
    ablankTable: "一张空白的桌子",
    import: "进口",
    name: "姓名",
    filesUpload: "浏览您的文件并上传您的",
    needsSupervisorApproval: "此行动需要主管的批准",
    chooseToSendRequest: "请选择您要将请求发送给谁",
    fields: {
      name: "姓名",
      actions: "行动",
    },
  },
  accessibilitySettingsPage: {
    subtitle: "配置排版、首選項、語言等。",
    typography: "版式",
    textPreferenceDescription: "在下面設定您的文字首選項。",
    textSize: "字體大小",
    language: "語言",
    mainLanguageDescription: "定義平台的主要語言。",
    selectLanguage: "選擇語言",
    changeUnitsText: "更改重量和測量單位",
    stillHavingDifficulties: "仍有困難嗎？",
    weWantToHelpYou: "我們想幫助您！ 以您喜歡的任何方式聯絡我們",
    contactViaPhone: "電話聯繫",
    contactViaEmail: "透過電子郵件聯繫",
    contactViaChat: "透過聊天聯繫",
  },
  usersPage: {
    title: "使用者",
    subtitle: "檢查連接的用戶並新增用戶。",
    all: "全部",
    sellerAdmin: "賣家管理員",
    sellerOperation: "賣家營運",
    sellerViewer: "賣家查看者",
    blocked: "被阻止",
    newUser: "新用戶",
    addUser: "新增用戶",
    editUser: "編輯使用者",
    allowAccess: "允許訪問",
    blockedTooltip:
      "如果您選取此框，使用者將被暫時禁止存取該平台。 您可以隨時取消此設置",
    groupTooltip: `賣家查看者只能查看數據，但不允許建立訂單、更改設定…
    賣家操作基本上可以做任何事情，除了建立/封鎖/刪除使用者。
    賣家管理員可以執行任何操作，包括建立/封鎖/刪除使用者。`,
  },
  contractsPage: {
    title: "合同",
    subtitle: "合同是公司和承运商合作的认证。",
    inconterm: "国际贸易术语",
    taxingType: "税收类型",
    startDate: "开始日期",
    endDate: "结束日期",
    downloadPdf: "下载PDF",
    downloadCsv: "下载CSV",
    footerHelpText: "了解更多关于承运商和合同的信息",
  },
  dashboardPage: {
    title: "儀表板",
    subtitle: "瀏覽選項卡以檢查數據",
    overview: "概述",
    salesTrend: "销售品牌",
    consumers: "消費者 | 消費者",
    totalNewCustomers: "{count} 個新客戶",
    totalCustomersReturned: "已返回 {count} 位客戶",
    new: "新的",
    recurrent: "復發性",
    topConsumers: "頂級消費者",
    showMore: "展示更多",
    showLess: "顯示較少",
    volumesByRegion: "按地區劃分的銷量",
    volumes: "卷",
    ordersCreated: "已建立訂單",
    ordersByStatus: "按狀態排列的訂單",
    seeOrders: "查看訂單",
    seeTableWithAllOrders: "請參閱包含所有訂單的表格",
    cards: {
      rejectOrder: "无效订单",
      freightValue: "运费值",
      freightCost: "运费",
      averageOrderCost: "订单平均金额",
    },
    totalOrders: "訂單總數",
    totalSales: "總銷售額",
    currentPeriod: "現時階段",
    bestSellers: "最暢銷",
    ordersByCategory: "按類別訂購",
    salesByChannel: "按通路銷售",
    ecommerce: "電子商務",
    period: "時期",

    noMoreOnboardText: `如果您不想看到此页面，请单击此处 - 您仍然可以在<i class="fas fa-user-graduate"></i><b> Onboard</b>)的侧边菜单中访问此页面`,
    customerOverview: "客户概述",
    startDate: "起始日期",
    endDate: "结束日期",
    clear: "清除",
    checkpoints: {
      new: "新的",
      orderInformationsReceived: "收到订单信息",
      readyToShip: "准备就绪",
      pickup: "拾件",
      shipped: "已装运",
      inTransit: "运输中",
      outForDelivery: "外出派送中",
      failedAttempt: "尝试失败",
      delivered: "已收件",
      exception: "错误",
      exceptionInPickup: "拾件中发生错误",
      partialDelivery: "部分签收",
      partialDeliveryFailed: "部分派送失败",
      expired: "超时",
      pending: "待定",
    },
    noShippingInThisState: "你没有运送到这个州的订单",
    createOrderHere: "再次创建新订单",
    moreInformation: "有关更多信息，请在此页面查看",
    checkYourInternet: "检查您的互联网连接并重试",
  },
  userProfileBalancePage: {
    title: "當前餘額",
    statement: "銀行對帳單",
    downloadReport: "下载报告",
    fields: {
      category: "类别",
      type: "类型",
      eventType: "事件类型",
      method: "方法",
      value: "数值",
      description: "描述",
      createdAt: "创建时间",
    },
  },
  trackingRuleNotificationsPage: {
    title: "追蹤通知規則",
    addConfig: "添加配置",
    createRule: "創建規則",
    giveRuleName: "為您的規則命名",
    ruleName: "規則名稱",
    applyRuleTo: "您想將規則應用於：",
    allShipments: "所有出貨",
    someShipmentsOnly: "僅部分出貨",
    chooseSellers: "首先，選擇將使用這些規則的賣家",
    WhenNewSellersIsCreated: "創建新賣家時，您想做什麼",
    addThisRuleAutomatically: "自動添加到此規則",
    addManually: "不要將其添加到此規則，如果需要，將手動添加",
    notifications: "通知",
    conditions: "條件",
    wouldYouLikeToRemoveRule: "您是否想要移除規則",
    wouldYouLikeToDisableRule: "您是否想要禁用規則",
    wouldYouLikeToEnableRule: "您是否想要啟用規則",
    removalIsFinal: "移除是不可逆的",
    disableRuleMessage:
      "該規則將保存在系統中，但不會應用於任何訂單，並立即生效。 這隨時可以撤銷。",
    enableRuleMessage: "該規則將立即應用於相關訂單。 這隨時可以撤銷。",
    sellersPermission: "賣家權限",
    sellersThatCanConfigureTheirOwnRules: "可以配置自己規則的賣家",
    sellersThatCanConfigureTheirOwnRulesDescription:
      "對於這些賣家，這裡創建的任何規則都不會適用，並且他們將能夠配置自己的規則",
    sellersThatWillUseRulesConfiguredHere: "將使用此處配置規則的賣家",
    sellersThatWillUseRulesConfiguredHereDescription:
      "這些賣家將無法配置自己的規則",
    createOrUseExistingRule: "您想創建或使用現有規則嗎？",
    createFromScratch: "從頭開始創建",
    useExistingRule: "使用現有規則作為預設配置",
    selectRuleToUse: "選擇您要使用的規則",
    defineNotificationRules: "為檢查點組定義通知規則",
    allSelected: "全選",
    selectAll: "全選",
    onSellerCreation: "創建新賣家時該怎麼做？",
    onContractCreation: "創建新合同時該怎麼做？",
    toEditSellersList: "要編輯賣家列表，您需要選擇：",
    toEditContractsList: "要編輯合同列表，您需要選擇：",
    autoAddRule: "自動添加到此規則",
    notAddThisRule: "不要將其添加到此規則，如果需要，將手動添加",
    searchByNameOrId: "搜索：按名稱或ID查找",
    noItemsSelected: "未選擇任何項目",
    selectedItems: "選中的項目",
    chooseContracts: "現在，選擇您希望將規則應用於以下合同",
    allShipmentWarning: "您即將創建適用於所有出貨的規則",
    keepEditing: "继续编辑",
    goIt: "明白了",
    wantToCreateRule: "您想创建配置的规则吗？",
    totalSellersLinked: "与此规则关联的卖家总数",
    totalContractsLinked: "与此规则关联的合同总数",
    allSellersIncluded: "所有卖家都已包含在此规则中，包括稍后将添加的新卖家。",
    allContractsIncluded:
      "所有合同都已包含在此规则中，包括稍后将添加的新合同。",
    almostThere: "快到了..",
    ruleCreatedSuccessfully: "规则创建成功！",
    youWantActivate: "目前已禁用。您现在要启用此规则吗？",
    maybeLater: "也许以后",
    notifsConfigured: "已配置 {total} 个通知",
    allSellers: "所有卖家",
    allContracts: "所有合同",
    totalSellers: "{total} 个卖家",
    totalContracts: "{total} 个合同",
    updateRule: "更新规则",
    wantToUpdateRule: "您想更新配置的规则吗？",
    ruleUpdatedSuccessfully: "规则更新成功！",
    activateUpdatedRule:
      "該規則已成功更新，並因安全性原因已停用。您想立即啟用該規則嗎？",
    youDontHaveContractAllowed:
      "您沒有任何允許的合約。請造訪 {platform} 以存取您的第一份合約",
    ruleNameAlreadyExists: "提供的規則名稱已存在",
  },
  fiscalPaymentsPage: {
    title: "税务支付",
    fiscalInfo: "税务信息",
    fiscalParcelData: "税务包裹数据",
    customerData: "客户数据",
    fields: {
      reference_number: "参考编号",
      barcode: "条形码",
      authentication: "认证",
      status: "状态",
      payment_type: "支付类型",
      value: "金额",
      fiscal_parcel_id: "税务包裹ID",
      payment_date: "支付日期",
      created_at: "创建日期",
      dir_number: "DIR编号",
      customer_name: "客户姓名",
      customer_cpf: "客户CPF",
      customer_cep: "客户CEP",
      tracking_number: "追踪编号",
      products_description: "产品描述",
      customer_state: "客户所在州",
      seller_id: "卖家ID",
      dir_date: "DIR日期",
      start_payment_date: "支付开始日期",
      end_payment_date: "支付结束日期",
      start_created_at: "创建开始日期",
      end_created_at: "创建结束日期",
      start_value: "初始金额",
      end_value: "最终金额",
      start_dir_date: "DIR开始日期",
      end_dir_date: "DIR结束日期",
      boletoGNRE: "GNRE票据",
      paymentDate: "支付日期",
      masterNumber: "Master Number",
      messages: "消息",
      courrierName: "Courrier Name",
      courrierCNPJ: "Courrier CNPJ",
    },
    status: {
      processing: "处理中",
      processed: "已处理",
      error: "错误",
    },
  },
};
//{{ $t('userProfileEditPage.title') }}
